import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.link.js";
import "core-js/modules/es.array.push.js";
import Mixin from "@/utils/mixin";
import { searchKeys, columns } from "./config/index";
import { tradingGroup } from "@/api";
import { dateFormat as _dateFormat } from "@/utils/dateutil";
var radio1List = [{
  id: "1",
  name: "是"
}, {
  id: "0",
  name: "否"
}];
var radio2List = [{
  id: "1",
  name: "有"
}, {
  id: "0",
  name: "无"
}];
export default {
  name: "tradingGroupManagement",
  components: {},
  data: function data() {
    return {
      popType: "check",
      whiteList: {},
      visible2: false,
      visible1: false,
      loadingSubmit: false,
      saleDetails: {},
      rules: {
        isplatformselection: [{
          required: true,
          message: "请选择是否平台精选",
          trigger: "change"
        }]
      },
      loadingType: "",
      loading: false,
      loading2: false,
      loading3: false,
      loading4: false,
      loading5: false,
      loading6: false,
      loading7: false,
      searchKeys: searchKeys,
      createForm: this.$form.createForm(this, {
        name: "whitelist_index"
      }),
      createFormValues: {},
      columns: columns,
      total: 0,
      page: 1,
      size: 20,
      isDeleteVisible: false,
      deleting: false,
      dataSource: [],
      tipsDesc: "",
      modalTitle: "添加白名单用户",
      modalVisible: false,
      visible: false,
      link: "",
      viewLoading: false,
      quitLoading: false
    };
  },
  mixins: [Mixin],
  computed: {
    loadingComputed: function loadingComputed() {
      return this.loadingType ? this[this.loadingType] : this.loading;
    },
    getDataWithKey: function getDataWithKey() {
      return function (key) {
        switch (key) {
          case "isplatformselection":
            return radio1List;
          case "iswhitelistbook":
            return radio2List;
        }
      };
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    checkDetail: function checkDetail(record) {
      var _this = this;
      if (this.viewLoading) return;
      this.viewLoading = true;
      tradingGroup.queryDetail({
        id: record.id
      }).then(function (res) {
        _this.saleDetails = res || {};
        _this.visible2 = true;
        _this.popType = "check";
      }).finally(function () {
        _this.viewLoading = false;
      });
    },
    editDetail: function editDetail(record) {
      var _this2 = this;
      if (this.viewLoading) return;
      this.viewLoading = true;
      tradingGroup.queryDetail({
        id: record.id
      }).then(function (res) {
        _this2.saleDetails = res || {};
        _this2.visible2 = true;
        _this2.popType = "edit";
      }).finally(function () {
        _this2.viewLoading = false;
      });
    },
    disabledDate: function disabledDate(current) {
      return "";
    },
    getList: function getList() {
      var _this3 = this;
      if (this.loadingType) {
        this[this.loadingType] = true;
      } else {
        this.loading = true;
      }
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          Object.keys(values).forEach(function (key) {
            if (!values[key]) delete values[key];
          });
          var params = _objectSpread(_objectSpread({}, values), {}, {
            size: _this3.size,
            page: _this3.page,
            groupleaderlevel: values.groupleaderlevel ? Number(values.groupleaderlevel) : ""
          });
          tradingGroup.queryList(params).then(function (res) {
            if (_this3.loadingType) {
              _this3[_this3.loadingType] = false;
            } else {
              _this3.loading = false;
            }
            _this3.dataSource = res.list;
            _this3.total = res.total;
          }).catch(function () {
            _this3.dataSource = [];
          }).finally(function (e) {
            setTimeout(function () {
              if (_this3.loadingType) {
                _this3[_this3.loadingType] = false;
              } else {
                _this3.loading = false;
              }
            }, 3000);
          });
        } else {
          console.log(err);
        }
      });
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    handleSearch: function handleSearch() {
      this.loadingType = "";
      this.page = 1;
      this.getList();
      // this.createForm.resetFields();
    },
    handleReset: function handleReset() {
      this.page = 1;
      this.size = 20;
      this.loadingType = "loading2";
      this.createForm.resetFields();
      this.page = 1;
      this.getList();
    },
    handleAddCancle: function handleAddCancle() {
      this.modalVisible = false;
    },
    handleAddSubmit: function handleAddSubmit() {
      this.modalVisible = false;
      this.getList();
    },
    dateFormat: function dateFormat(time) {
      if (!time) return "---";
      return _dateFormat(time, "yyyy-MM-dd hh:mm:ss");
    },
    handlePreview: function handlePreview(url) {
      this.link = url;
      this.visible = true;
    },
    hadnleQuit: function hadnleQuit(id) {
      var _this4 = this;
      this.quitLoading = true;
      tradingGroup.dissolveGroup({
        id: id
      }).then(function (res) {
        if (res) {
          _this4.$message.success('解散成功');
        }
        _this4.quitLoading = false;
        _this4.getList();
        _this4.visible2 = false;
      }).catch(function () {}).finally(function () {
        _this4.quitLoading = false;
      });
    },
    toUserDetail: function toUserDetail(uid) {
      if (!uid) {
        this.$message.error("用户信息有误！");
        return;
      }
      var path = "/user/detail?uid=".concat(uid);
      this.$router.push(path);
    }
  }
};